<template>
  <div class="main-box" v-if="info">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/order/index' }">订单</el-breadcrumb-item>
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-steps :active="stepIdx" finish-status="success" simple class="mg-tp-20" v-if="step">
      <el-step :title="item" v-for="(item,index) in step" :key="index"></el-step>
    </el-steps>
    <el-row :gutter="60" class="mg-tp-20">
      <el-col :span="12" style="border-right: 1px solid #f5f5f5">
        <el-divider content-position="left">订单详情</el-divider>
        <el-form size="small" label-position="left">
          <el-form-item label="订单号：" :label-width="formLabelWidth">
            {{ info.order_sn }}
          </el-form-item>
          <el-form-item label="下单时间：" :label-width="formLabelWidth">
            {{ info.ftime }}
          </el-form-item>
          <el-form-item label="状态：" :label-width="formLabelWidth">
            {{ info.status_str }}
          </el-form-item>
          <el-form-item label="支付状态：" :label-width="formLabelWidth">
            {{ info.pay_status==1?'已支付':'未支付' }}
          </el-form-item>
          <el-form-item label="取消时间：" :label-width="formLabelWidth" v-if="info.cancel_time">
            {{ info.cancel_time }}
          </el-form-item>
          <el-form-item label="支付时间：" :label-width="formLabelWidth" v-if="info.pay_time">
            {{ info.pay_time }}
          </el-form-item>
          <el-form-item label="交易单号：" :label-width="formLabelWidth" v-if="info.payment_sn">
            {{ info.payment_sn }}
          </el-form-item>
          <el-form-item label="微信单号：" :label-width="formLabelWidth" v-if="info.trade_sn">
            {{ info.trade_sn }}
          </el-form-item>
          <el-form-item label="发货时间：" :label-width="formLabelWidth" v-if="info.shipments_time">
            {{ info.shipments_time }}
          </el-form-item>
          <el-form-item label="收货时间：" :label-width="formLabelWidth" v-if="info.delivery_time">
            {{ info.delivery_time }}
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <el-divider content-position="left">买家信息</el-divider>
        <el-form size="small" label-position="left">
          <el-form-item label="买家：" :label-width="formLabelWidth" v-if="info.member">
            {{ info.member.name }}（{{ info.member.user_id }}） - {{ info.member.mobile }}
          </el-form-item>
          <el-form-item label="配送方式：" :label-width="formLabelWidth" v-if="info.delivery_type>0">
            {{ info.delivery_type==1?'物流配送':info.delivery_type==2?'上门自提':'-' }}
          </el-form-item>
          <el-form-item label="收货人：" :label-width="formLabelWidth" v-if="info.address">
            {{ info.address.name }} - {{ info.address.mobile }}
          </el-form-item>
          <el-form-item label="收货地址：" :label-width="formLabelWidth" v-if="info.address">
            {{ info.address.province_name }} {{ info.address.city_name }} {{ info.address.district_name }} {{ info.address.address }}
          </el-form-item>
          <div v-if="info.package">
            <el-form-item label="发货信息：" :label-width="formLabelWidth">
              <span v-if="info.package.logis_type==1">
                {{ info.package.logis_name }} - {{ info.package.logis_no }}
                <el-button type="text" size="medium" @click="lookLog()">查看物流</el-button>
                <span class="mg-lf-20">备注：{{info.package.remark}}</span>
              </span>
              <span v-else>
                {{info.order_goods_type==2?'虚拟发货':'无需物流'}} <span class="mg-lf-20">备注：{{info.package.remark}}</span>
              </span>
              <ul class="picList" v-if="info.package.pics && info.package.pics.length>0">
                <li v-for="(item,index) in info.package.pics" :key="index">
                  <img class="image-img" :src="item" />
                </li>
              </ul>
            </el-form-item>
          </div>
          <div v-if="info.selffetch">
            <el-form-item label="自提点：" :label-width="formLabelWidth">
              {{ info.selffetch.store_name }}
            </el-form-item>
            <el-form-item label="提货人：" :label-width="formLabelWidth" v-if="info.selffetch">
              {{ info.selffetch.name }} - {{ info.selffetch.mobile }}
            </el-form-item>
            <el-form-item label="核销人：" :label-width="formLabelWidth" v-if="info.selffetch.hexiao_status==1">
              {{ info.selffetch.user_name }}
            </el-form-item>
            <el-form-item label="核销时间：" :label-width="formLabelWidth" v-if="info.selffetch.hexiao_status==1">
              {{ info.selffetch.hexiao_time }}
            </el-form-item>
          </div>
          <el-form-item label="用户备注：" :label-width="formLabelWidth">
            {{ info.remark }}
          </el-form-item>
          <el-form-item label="自定义留言：" :label-width="formLabelWidth" v-if="info.order_fields && info.order_fields.length>0">
            <div v-for="(item,index) in info.order_fields" :key="index">{{item.title}}：{{item.value}}</div>
          </el-form-item>

          <el-form-item label="">
            <el-popconfirm v-if="info.status==0" class="mg-rt-10"
                title="确认取消当前订单吗？"
                @confirm="orderCancel"
            >
              <el-button type="primary" size="mini" slot="reference">取消</el-button>
            </el-popconfirm>
            <el-button type="warning" size="mini" @click="dialogVisible=true" v-if="info.status==1">发货</el-button>
            <el-popconfirm
                v-if="info.status==4"
                class="mg-rt-10"
                title="确认核销当前订单吗？"
                @confirm="hexiaoOrder"
            >
              <el-button type="primary" slot="reference" size="mini">核销</el-button>
            </el-popconfirm>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-divider content-position="left">商品信息</el-divider>
    <el-table
        :data="info.goods"
        ref="rfTable"
        border
        style="width: 100%"
        :span-method="objectSpanMethod"
        size="mini">
      <el-table-column
          prop="goods"
          label="商品">
        <template slot-scope="scope">
          <div class="goods-box line">
            <div class="item">
              <img :src="scope.row.goods_img" class="img" />
              <div>
                <div class="name">{{scope.row.goods_name}}</div>
                <div class="spec">{{scope.row.specs_name}}</div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="quantity"
          label="数量"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="price"
          label="单价(元)"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="pay_price"
          label="商品总价(元)"
          align="center"
          width="120">
        <template slot-scope="scope">
          {{ info.goods_amount }} {{scope.row.id}}
        </template>
      </el-table-column>
      <el-table-column
          prop="pay_price"
          label="优惠(元)"
          align="center"
          width="120">
        <template slot-scope="scope">
          {{ (scope.row.price*scope.row.quantity-scope.row.pay_price).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column v-if="info.refund_status>0"
          prop="refund_status"
          label="售后"
          align="center"
          width="120">
        <template slot-scope="scope">
          {{scope.row.id}}
          <el-button type="text" v-if="info.refund_status==1" size="small" @click="orderRefundDetail()">退款申请中</el-button>
          <el-button type="text" v-if="info.refund_status==2" size="small" @click="orderRefundDetail()">退款完成</el-button>
        </template>
      </el-table-column>
      <el-table-column
          prop="pay_price"
          label="小计(元)"
          align="center"
          width="120">
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="shipment_fee"-->
<!--          label="运费(元)"-->
<!--          align="center"-->
<!--          width="120">-->
<!--        <template slot-scope="scope">-->
<!--          {{ info.shipment_fee }} {{scope.row.id}}-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <el-row :gutter="60" class="mg-tp-20 mg-bt-20 pd-bt-20">
      <el-col :span="24" style="border-right: 1px solid #f5f5f5">
        <el-divider content-position="left">汇总</el-divider>
        <el-form size="small" label-position="left">
          <el-form-item label="商品总价：" :label-width="formLabelWidth">
            ￥{{ info.goods_amount }}
          </el-form-item>
        </el-form>
        <el-form size="small" label-position="left" v-for="(item,index) in info.ump" :key="index">
          <el-form-item :label="item.ump_type" :label-width="formLabelWidth">
            -￥{{ item.amount }}
          </el-form-item>
        </el-form>
        <el-form size="small" label-position="left">
          <el-form-item label="实付金额：" :label-width="formLabelWidth">
            ￥{{ info.amount }} （含运费：￥{{ info.shipment_fee }}）
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-dialog
        title="订单发货"
        :visible.sync="dialogVisible"
        :destroy-on-close="true"
        width="600px">
      <order-delivery :orderId="id" :callback="res => { dialogVisible=false; getOrderInfo() }"></order-delivery>
    </el-dialog>
    <el-dialog
        title="物流信息"
        :visible.sync="dialogLogVisible"
        :destroy-on-close="true"
        width="600px">
      <el-steps direction="vertical">
        <el-step :description="item.remark" :title="item.datetime" v-for="(item,index) in deliveryLogList" :key="index" status1="process">
          <span slot="title" class="c333 font14">{{item.datetime}}</span>
          <span slot="description" class="c333" style="display: inline-block; padding-bottom: 20px;">{{item.remark}}</span>
        </el-step>
      </el-steps>
    </el-dialog>
  </div>
</template>
<script>

import ClipboardJS from "clipboard";
import OrderDelivery from "@/components/Order/OrderDelivery";
export default {
  components: {
    OrderDelivery
  },
  data() {
    return {
      id: 0,
      info: false,
      formLabelWidth: '90px',
      dialogVisible: false,
      dialogLogVisible: false,
      step: false,
      stepIdx: -1,
      deliveryLogList: []
    };
  },
  created() {
    if (this.$route.params.id) {
      this.id = parseInt(this.$route.params.id)
      this.getOrderInfo()
    }
  },
  mounted() {
  },
  computed: {},
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex >= this.info.goods.length+2) {
        return {
          rowspan: 3,
          colspan: 1
        };
      }
    },
    lookLog() {
      this.showLoading()
      this.$api.order.orderDeliveryLog({id:this.id}, res => {
        this.hideLoading()
        if (res.errcode == 0) {
          this.deliveryLogList = res.data
          this.dialogLogVisible = true
        } else {
          this.fail(res.errmsg)
        }
      });
    },
    getOrderInfo() {
      this.$api.order.orderDetail({id:this.id}, res => {
        if(res.errcode==0) {
          this.info = res.data
          var step = [];
          if(this.info.delivery_type==1) {
            step.push('买家下单')
            step.push('买家支付')
            step.push('卖家发货')
            step.push('订单完成')
            if(this.info.status==0) {
              this.stepIdx = 1
            } else if(this.info.status==1) {
              this.stepIdx = 2
            } else if(this.info.status==2) {
              this.stepIdx = 3
            } else if(this.info.status==3) {
              this.stepIdx = 4
            } else {
              step = false
            }
          } else if(this.info.delivery_type==2) {
            step.push('买家下单')
            step.push('买家支付')
            step.push('上门提货')
            step.push('订单完成')
            if(this.info.status==0) {
              this.stepIdx = 1
            } else if(this.info.status==1) {
              this.stepIdx = 2
            } else if(this.info.status==4) {
              this.stepIdx = 3
            } else if(this.info.status==3) {
              this.stepIdx = 4
            } else {
              step = false
            }
          }
          this.step = step
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    copyTxt(txt) {
      var _this = this
      var clipboard = new ClipboardJS('#copyId', {
        text: function () {
          return txt;
        },
      });
      clipboard.on('success', function (e) {
        _this.success('复制成功')
      });
      clipboard.on('error', function (e) {
        _this.fail('复制失败')
      });
    },
    orderCancel() {
      var _this = this
      this.$api.order.orderCancel({id: this.id}, res => {
        if (res.errcode == 0) {
          _this.success('取消成功')
          _this.getOrderInfo()
        } else {
          _this.fail(res.errmsg)
        }
      })
    },
    hexiaoOrder() {
      var params = {
        type: 1,
        is_hexiao: 1,
        code: this.info.selffetch.hexiao_code
      }
      this.showLoading()
      this.$api.order.orderHexiao(params, res => {
        this.hideLoading()
        if(res.errcode==0) {
          this.success(res.errmsg)
          this.getOrderInfo()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    orderRefundDetail() {
      this.$router.push({name: 'order_refund_detail', params: {id: this.info.id}})
    }
  }
};
</script>
<style scoped>
.gdtable tr {
  background-color: unset;
  border-bottom: 1px solid #f5f5f5
}
.gdtable tr:last-child {
  border-bottom: none !important;
}
.gdtable td {
  padding: 5px 0;
}
.el-form-item {
  margin-bottom: 5px;
}
/* 移除table组件hover背景色 */
.el-table__row:hover> td{
  background: #fff !important;
}
</style>
